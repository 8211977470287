<template>
  <div class="learnListBox">
    <!-- v-if="!tabIndex" -->
    <div class="top-content">
      <div class="contentBox">
        <div class="item">
          <p class="num">
            {{
              this.$dateFormat.transSecondstoHour(
                +learnInfo?.todayLearnTime || 0
              )
            }}
          </p>
          <p class="label">今日累计学习(小时)</p>
        </div>
        <div class="item">
          <p class="num">
            {{
              this.$dateFormat.transSecondstoHour(
                +learnInfo?.thisWeekLearnTime || 0
              )
            }}
          </p>
          <p class="label">本周累计学习(小时)</p>
        </div>
        <div class="item">
          <p class="num">
            {{
              this.$dateFormat.transSecondstoHour(
                +learnInfo?.thisMonthLearnTime || 0
              )
            }}
          </p>
          <p class="label">本月累计学习(小时)</p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="tabBox">
        <div class="tab1">
          <p class="tabItem" :class="{tabActive:tabIndex == item.id}" @click="handleTab(item.id)"  
            v-for="(item, index) in tabs" :key="index">
            <svg-icon :iconClass="index==0?'tabActive1':'tabActive'" className="iconBg" v-if="tabIndex == item.id"></svg-icon>
            <span><svg-icon :iconClass="item.key?('tab-'+item.key):item.icon"></svg-icon>{{ item.value }}</span>
          </p>
        </div>
        <div class="tab2" v-if="[1, -1, 4].includes(tabIndex)">
          <p class="tabItem2" :class="{ tabActive: statusIndex === index }" v-for="(item, index) in courseStatusList"
            :key="item.text" @click="handleStatus(index, item)">
            {{ item.text }}
          </p>
        </div>
        <div class="tab2" v-if="[5].includes(tabIndex)">
          <p class="tabItem2" :class="{ tabActive: statusIndex === index }" v-for="(item, index) in liveStatusList"
            :key="item.text" @click="handleStatus(index, item)">
            {{ item.text }}
          </p>
        </div>
      </div>
      <plan-list v-if="tabIndex==-2" :taskStatus="taskStatus" :key="taskStatus"></plan-list>
      <allCoutseList v-if="tabIndex == -1" ref="courseListRef" :taskStatus="taskStatus" :tabIndex="tabIndex"
        :key="taskStatus" />

      <studyTask v-if="tabIndex == 3" class="padding24" ref="studyTaskRef" :pageNums="12" />
      <liveList
        ref="liveListRef"
        v-if="tabIndex === 5"
        :taskStatus="taskStatus"
        :key="taskStatus"
      /> 
      <questionnairelist v-if="tabIndex==6"></questionnairelist>

    </div>
  </div>
</template>

<script>
import studyTask from "ss-pc/src/modules/learn/page/studyTask.vue";
import allCoutseList from "ss-pc/src/modules/learn/page/allCoutseList.vue";
import questionnairelist from 'ss-pc/src/modules/learn/page/questionnaireList.vue'
import liveList from "ss-pc/src/modules/learn/page/liveList";
import PlanList from "./planList.vue";
export default {
  components: {studyTask, allCoutseList,liveList,PlanList,questionnairelist },
  data() {
    return {
      courseStatusList: [
        {
          id: 0,
          text: "全部",
        },
        {
          id: 1,
          text: "未开始",
        },
        {
          id: 2,
          text: "进行中",
        },
        {
          id: 3,
          text: "已结束",
        },
      ],
      liveStatusList: [
        {
          id: 0,
          text: "全部",
        },
        {
          id: 2,
          text: "直播中",
        },
        {
          id: 1,
          text: "未开始",
        },
        {
          id: 3,
          text: "已结束",
        },
      ],
      tabs: [
      {
          value: '培训计划',
          id: -2,
          icon: 'tab-training'
        },
        {
          value: '培训任务',
          id: -1,
          icon: 'tab-project'
        },
        {
          value: '新员工任务',
          id: 3,
          icon: 'tab-inductionTraining'
        },
        {
          value: '直播',
          id: 5,
          icon: 'tab-live'
        },
        {
          value: '问卷',
          id: 6,
          icon: 'tab-course'
        }
      ],
      tabIndex: -2,
      statusIndex: 0,
      learnInfo: null,
      taskStatus: 0,
    };
  },
  watch: {
    tabIndex: {
      handler() {
        this.taskStatus = 0;
        this.statusIndex = 0;
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.tabIndex = +localStorage.getItem("currentTabIndex") || -2;
    this.getAllLearnedData();
  },
  methods: {
    getAllLearnedData() {
      this.$api.learn.getAllLearnedData().then((res) => {
        this.learnInfo = res.data || null;
      });
    },
    handleTab(index) {
      this.tabIndex = index;
      localStorage.setItem("currentTabIndex", this.tabIndex);
    },
    handleStatus(index, item) {
      this.statusIndex = index;
      this.taskStatus = item.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.textSelect{
   color: #fff;
}
.tabActiveTabAll{
  width: 160px;
  height: 67px;
  background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/selectTabAll.png') no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabActiveTabOne{
  width: 160px;
  height: 67px;
  background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/selectTabOne.png') no-repeat;
  display: flex;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabImg {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}
</style>
<style lang="scss" src="../asset/css/index.scss" scoped></style>
